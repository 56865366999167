<template>
  <div>
    <create-pass />
    <div
      v-if="mobile"
      id="journal-cards"
    >
      <v-card
        v-for="(item, idx) in itemsModified"
        :key="`card-${idx}`"
        height="150"
        class="d-flex mb-3 pa-1"
        :class="getItemClass(item)"
      >
        <v-row>
          <v-col>
            <JournalUser
              :item="item"
              :user-id="userId"
            />
          </v-col>
          <v-col cols="3">
            {{ item.id }}
          </v-col>
          <v-col>
            <JournalActions
              :item="item"
              @perform="onPerform"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-data-footer
        :pagination="cardsPagination"
        :options="options"
        :items-per-page-options="[15, 30, 50]"
        :show-first-last-page="false"
        @update:options="onCardsOptsUpdate"
      />
    </div>
    <v-data-table
      v-else
      class="rounded-t-0 border-t-0"
      :items="itemsModified"
      :headers="headers"
      disable-sort
      :item-class="getItemClass"
      :options="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [15, 30, 50],
        'show-first-last-page': false,
      }"
      @click:row="bufferDocument"
      @update:items-per-page="setPaginationSize"
      @update:page="setPaginationPage"
      @mouseover.native="bufferDocumentMouseMove"
    >
      <template #[`item.declarant`]="{ item }">
        <JournalUser
          :item="item"
          :user-id="userId"
        />
      </template>
      <template #[`item.declaration_id`]="{item}">
        <span
          class="cursor-pointer primary--text"

          @click="showParentDocument(item)"
        >
          {{ item.declaration_id }}
        </span>
      </template>

      <template #[`item.transport_number`]="{ item }">
        <span>{{ item.transport_number }}</span><br v-if="item.trailers">
        <span>{{ item.trailers }}</span>
      </template>
      <template #[`item.seal`]="{ item }">
        <v-icon
          :color="item.seal ? 'green': 'red'"
          class="font-weight-bold"
        >
          mdi-circle-medium
        </v-icon>
      </template>
      <template #[`item.actions`]="{ item }">
        <JournalActions
          :item="item"
          @perform="onPerform"
        />
      </template>
    </v-data-table>
    <component
      :is="activeComponent"
      :id="selectedId"
      :root-user-id="rootUserId"
      :show.sync="showComponent"
      module="uvr"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import JournalItemECLIENT from "@/components/journal/journal-item-eclient.vue";
import {methods, actions} from "./functions";
import JournalItemNCES from "@/components/journal/journal-item-nces.vue";
import JournalTableMixin from "@/mixins/journal-table.mixin";
import JournalItemBD44 from "@/components/journal/journal-item-bd44.vue";
import DocumentIdentity from "@/components/journal/document-identity.vue";
import CreateFromTransit from "@/components/pass/journal/create-pass.vue";
import moment from "moment";
import CreatePass from "@/components/pass/journal/create-pass.vue";

export default {
  components: {
    CreatePass,
    CreateFromTransit,
    DocumentIdentity,
    JournalItemBD44,
    JournalItemNCES,
    JournalItemECLIENT,
  },
  mixins:[JournalTableMixin],
  data: () => ({
    actions,
    headers: [
      {
        text: "",
        value: "declarant",
      },
      {
        text: "Место",
        value: "parking_place_number",
      },
      {
        text:'УВР',
        value:'declaration_id'
      },
      {
        text: "Дата въезда",
        value: "entry_time_formatted",
      },
      {
        text: "Номер ТС",
        value: "transport_number",
      },

      {
        text: "ФИО",
        value: "driver_last_name",
      },
      {
        text: "Телефон",
        value: "driver_phone",
      },
      {
        text: "Вид операции",
        value: "entry_type",
      },

      {
        text: "Дата выезда",
        value: "departure_time_formatted",
      },
      {
        text: "НЭП",
        value: "seal",
      },
      {
        text: "",
        value: "actions",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      items: "journal/getItems",
      total: "journal/totalCount",
      pagination: "journal/pagination",
      user: "auth/getUser",
    }),
    itemsModified() {
      return this.items.map((item) => {
        const {
          id,
          declaration_id,
          user_id,
          last_name,
          entry_time,
          transport_number,
          first_trailer_number,
          second_trailer_number,
          driver_last_name,
          driver_phone,
          parking_place_number,
          entry_type,
          departure_time,
          seal,
          first_name,
          email,
          phone,
          is_trash,
          total_count,
        } = item;
        const trailers = [first_trailer_number,second_trailer_number].filter((v) => v).join('/')
        const entry_time_formatted = moment(entry_time).isValid() ? moment(entry_time).format('DD.MM.YYYY HH:mm:ss'): null
        const departure_time_formatted = moment(departure_time).isValid() ? moment(departure_time).format('DD.MM.YYYY HH:mm:ss'): null
        const initials = this.getInitials({first_name, last_name});
        const fullname = this.getFullName({first_name, last_name});
        const actions = this.getItemActions(item);
        return {
          actions, // меню с действиями
          declaration_id,
          trailers,
          entry_time,
          entry_time_formatted,
          transport_number,
          first_trailer_number,
          second_trailer_number,
          driver_last_name,
          driver_phone,
          parking_place_number,
          entry_type,
          departure_time,
          departure_time_formatted,
          seal,
          is_trash,
          total_count,
          fullname,
          last_name,
          first_name,
          phone,
          email,
          id,
          initials,
          user_id,
        };
      });
    },
  },

  methods: {
    ...methods, // методы для каждого элемента
    showParentDocument(item){
      const {declaration_id} = item
      if(!declaration_id) return
      const route = this.$router.resolve({
        path: `/uvr/${declaration_id}`
      });
      window.open(route.href ,'_blank')
    },
    getDocumentTypeActions(status) {
      const actions = [...this.actions];
      if (status.myRequestDocument) {
        const available = [];
        return actions.filter((i) => {
          return available.includes(i?.action) || i === null;
        });
      } else if (status.isRequest) {
        return [];
      } else if (status.documentFromRequest) {
        const config = {
          createPass: ["doc.div.pdf.create"],
        };
        return this.modifyActions(this.getAvailableActions(config), status);
      } else if (status.myDocument) {
        const config = {
          createPass: ["doc.div.copy.create"],
        };
        return this.modifyActions(this.getAvailableActions(config), status);
      } else if (!status.myDocument) {
        const config = {
          createPass: ["doc.div.copy.create"],
        };
        return this.modifyActions(this.getAvailableActions(config), status);
      } else {
        return [];
      }
    },
    modifyActions(actions, status) {
      if (!status) return actions;
      return actions.map((i) => {
        if (i?.action === "deleteDocument" && status.isTrash) {
          return {
            ...i,
            icon: "mdi-delete-off",
            name: "Восстановить",
          };
        } else if (i?.action === "changeDeferredStatus" && status.isDeffered) {
          return {
            ...i,
            icon: "mdi-play",
            name: "Возобновить",
          };
        }
        return i;
      });
    },
  },
};
</script>

<style scoped>
.isApplication {
  background-color: blue !important;
}

.v-data-footer__select {
  display: block;
}
</style>


const actions = [

]

const methods = {

}

export {
  methods, actions
}
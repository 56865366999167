<template>
  <v-row class="mx-0 pb-4">
    <v-col
      cols="1"
      class="teal lighten-2 d-flex align-center"
    >
      <v-btn
        color="teal lighten-2"
        class="elevation-0"
        @click="openTransitList"
      >
        <v-icon>mdi-link</v-icon>
        ЭПИ
      </v-btn>
    </v-col>
    <v-col cols="11">
      <v-row class="grey lighten-2">
        <v-col
          cols="1"
          class="pr-0"
        >
          <label>Тягач</label>
          <v-text-field
            v-model="form.transport_number"
            outlined
            dense
            hide-details
            background-color="white"
          />
        </v-col>
        <v-col
          cols="1"
          class="pr-0"
        >
          <label>Прицеп №1</label>
          <v-text-field
            v-model="form.first_trailer_number"
            outlined
            dense
            hide-details
            background-color="white"
          />
        </v-col>
        <v-col
          cols="1"
          class="pr-0"
        >
          <label>Прицеп №2</label>
          <v-text-field
            v-model="form.second_trailer_number"
            outlined
            dense
            hide-details
            background-color="white"
          />
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>ФИО</label>
          <v-text-field
            v-model="form.driver_last_name"
            outlined
            dense
            hide-details
            background-color="white"
          />
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>Телефон</label>
          <v-text-field
            v-model="form.driver_phone"
            outlined
            dense
            hide-details
            background-color="white"
          />
        </v-col>
        <v-col cols="1">
          <label>Место</label>
          <input-numeric
            v-model="form.parking_place_number"
            :fraction="0"
            outlined
            dense
            hide-details
            background-color="white"
          />
        </v-col>
        <v-col
          cols="1"
          align-self="center"
        >
          <v-checkbox
            v-model="form.wares"
            color="black"
            hide-details
            label="Наличие товара"
            class="mr-2"
          />
        </v-col>
        <v-col
          cols="1"
          align-self="center"
        >
          <v-checkbox
            v-model="form.seal"
            color="black"
            hide-details
            label="НЭП"
          />
        </v-col>
        <v-col
          cols="2"
          align-self="center"
          class="d-flex"
        >
          <v-btn
            :disabled="disabled"
            class="elevation-0 teal lighten-2 rounded-r-0"
            @click="submit"
          >
            <v-icon>mdi-plus</v-icon>
            Пропуск
          </v-btn>
          <v-btn
            dark
            class="rounded-l-0 elevation-0"
            min-width="38px"
            width="38px"
            @click="clear"
          >
            <v-icon>
              mdi-broom
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <component
      :is="activeComponent"
      :show.sync="show"
    />
  </v-row>
</template>
<script>
import {mapGetters} from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";

function initialFormState(){
  return {
    transport_number: null,
    first_trailer_number: null,
    second_trailer_number: null,
    driver_last_name: null,
    driver_phone: null,
    parking_place_number: null,
    wares: false,
    seal: false
  }
}
export default {
  components: {InputNumeric},
  data() {
    return {
      activeComponent: null,
      show: false,
      vehicles: [],
      form: initialFormState()
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    }),
    disabled(){
      return ['transport_number','driver_last_name','driver_phone','parking_place_number'].some((key) => !this.form[key])
    }
  },
  methods: {
    clear(){
      this.form = initialFormState()
    },
    submit() {
      const payload = {
        ...this.form,
        id: null,
        declaration_id: null,
        user_id: null,
        division_id: null,
        container_number: null,
        entry_time: null,
        departure_time: null,
        entry_type: null,
      }
      this.$store.dispatch(`uvr/createPassWithForm`, payload).then((res) => {
        this.$success(`Создан пропуск: ${res.data.id}`)
        this.clear()
      }).catch(() => this.$error())
    },
    openTransitList() {
      this.activeComponent = () => import('@/components/pass/journal/select-epi-documents.vue')
      this.show = true
    },
  }
}
</script>
